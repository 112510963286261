@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Lexend:wght@400;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

.font-lexend {
  font-family: 'Lexend', sans-serif;
}

@media screen and (max-width: 800px) {
  .show-on-medium-large {
    display: none;
  }
}

.show-on-small {
  display: none;
}

@media screen and (max-width: 800px) {
  .show-on-small {
    display: block;
  }
}

.navbar-container {
  display: flex; /* Esto alinea los elementos (enlaces) horizontalmente */
  justify-content: space-around; /* Esto distribuye el espacio entre los enlaces de manera uniforme */
  align-items: center; /* Esto centra los enlaces verticalmente */
  width: 100%;
}

.navbar-container a:hover, .menu-content a:hover {
  background-color: rgb(65, 135, 214); /* Cambia el color de fondo a azul */
  color: white; /* Cambia el color del texto a blanco para mejor contraste */
}

.small-navbar-container {
  display: flex; /* Esto alinea los elementos (enlaces) horizontalmente */
  justify-content: space-between; /* Esto distribuye el espacio entre los enlaces de manera uniforme */
  align-items: center; /* Esto centra los enlaces verticalmente */
  width: 100%;
}

.menu-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  display: none; /* Oculto por defecto */
}

.menu-content.show {
  display: block; /* Mostrar cuando isOpen es true */
}

.menu-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.menu-content a:hover {
  background-color: #ddd;
}

